import logo from './logo.svg';
import './App.css';

import Home from './pages/home';
import Checkin from './pages/checkin';
import Livefeed from './pages/livefeed';
import Booths from './pages/booths';
import Shelf from './pages/shelf';

import { BrowserRouter, Routes, Route} from 'react-router-dom';


  

  function App() {

  return (
    <BrowserRouter>
    <Routes>
      <Route path="/">
        <Route index element={<Home />} />
        <Route path="checkin" element={<Checkin />} />
        <Route path="livefeed" element={<Livefeed />} />
        <Route path="booths" element={<Booths />} />
        <Route path="shelf" element={<Shelf />} />
        
      </Route>
    </Routes>
  </BrowserRouter>
    
  );
}





export default App;
