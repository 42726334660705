import React, { useEffect, useState } from "react";
import axios from 'axios';
import styled from 'styled-components';








function Shelf() {
   

    const Button = styled.button`
    background-color: black;
    color: white;
    font-size: 20px;
    padding: 10px 60px;
    border-radius: 5px;
    margin: 10px 0px;
    cursor: pointer;
    width:100%
  `;

  let HandleProfileChange = (e) => {
    console.log(e);
    filterWines("Profile", e.target.innerText  );
  }

  const ButtonToggle = styled(Button)`
    opacity: 0.6;
    ${({ active }) =>
      active &&
      `
      opacity: 1;
    `}
  `;
  const ButtonGroup = styled.div`
    
  `;
  const types = ['None','Sweet Avenger', 'Savory Crusader', 'Body Advocate', 'Balance Ambassador', 'Tannin Champion'];
  function ToggleGroup() {
    const [active, setActive] = useState(types[0]);
    return (
      <ButtonGroup>
        {types.map(type => (
          <ButtonToggle
            key={type}
            active={active === type}
            onClick={HandleProfileChange}
            //onClick={() => setActive(type)}
            
          >
            {type}
          </ButtonToggle>
        ))}
      </ButtonGroup>
    );
  }

   
    
    return (

      <div>
            <div style={{ top:0,position:'sticky',paddingLeft:40,lineHeight:'60px',color:'white',width:'100%',backgroundColor:'#6e6e72',height:60, borderBottomColor:'#f69521',borderBottomWidth:5,borderBottomStyle:'solid'}}>
                <a style={{textDecoration:'none',color:'black',verticalAlign:'middle'}} href="/"><img src='/img/left-chevron.png' style={{height:24,filter:'invert(100%)'}}/> <img style={{height:40,marginTop:10}} src="img/GallovationLogoWhite.png" /></a> 
            </div>
            <div style={{ margin:'40px'}}>

                <div>
                    <h1>What's your wine style?</h1>
                    <p>Select your style to see which bottles on the shelf best match your taste preferences. If you don't know your style, be sure to visit the vinotastr booth to find out!</p>

<center>
                    <button onClick={HandleProfileChange} style={{backgroundColor:'yellow',width:'90%',height:50,fontSize:22,marginBottom:5}} >Sweet Avenger</button><br/>
                    <button onClick={HandleProfileChange} style={{backgroundColor:'blue',width:'90%',height:50,fontSize:22,color:'white',marginBottom:5}} >Balance Ambassador</button><br/>
                    <button onClick={HandleProfileChange} style={{backgroundColor:'red',width:'90%',height:50,fontSize:22,color:'white',marginBottom:5}} >Savory Crusader</button><br/>
                    <button onClick={HandleProfileChange} style={{backgroundColor:'purple',width:'90%',height:50,fontSize:22,color:'white',marginBottom:5}} >Tannin Champion</button><br/>
                    <button onClick={HandleProfileChange} style={{backgroundColor:'green',width:'90%',height:50,fontSize:22,color:'white',marginBottom:5}} >Body Advocate</button>                    
                    </center>     
                    
                    <div style={{textAlign:'center',marginTop:30}}>powered by:<br/><img style={{height:50}}src="/img/vinotastr.png"/></div>
                </div>
            </div>
                    
        </div>
    );
}

async function filterWines(FilterType, FilterValue) {

    var value = '{"' + FilterType + '": "' + FilterValue + '"}';
    console.log(value);
    var sendobj = JSON.parse(value);
console.log(FilterType + " " + FilterValue);
const response = await axios.post('https://zegdrs3yh5.execute-api.us-east-1.amazonaws.com/ShelfProd/show', sendobj);
console.log(response.data);
}


export default Shelf;

