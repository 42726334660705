import React, { useEffect, useState } from "react";
import axios from 'axios';


import { Auth } from 'aws-amplify';
import awsconfig from '../bws-exports';

var path = window.location.href;
if (path.includes("?")) {
    path = window.location.href.slice(0, window.location.href.lastIndexOf('?'));
    console.log(path);
}

if(path.includes("#")) {
    path = window.location.href.slice(0, window.location.href.lastIndexOf('#'));

}

awsconfig.oauth.redirectSignIn = path;




function Booths() {
    const [booth, setBooth] = useState({});

    useEffect(() => {
        var isauth = false;

        Auth.currentAuthenticatedUser({
            bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
            .then((user) => {
                console.log(user);
                isauth = true;

                axios.get('https://76hwbexd1g.execute-api.us-east-1.amazonaws.com/prod/booth?BoothID=')
                    .then(response => {

                        setBooth(response.data.body);


                    });
            })
            .catch((err) => {
                console.log(err);
                if (!isauth) {
                    Auth.federatedSignIn({ customProvider: "Gallo" });
                }
            })




    }, []);
    if (!booth) return null;
    if (!Array.isArray(booth)) return null;
    return (
        <div><h1>booths!</h1>
            <ul>
                {booth.map(data => (
                    <li key={data.id}>{data.Title}</li>
                ))}
            </ul>
        </div>
    );
}



export default Booths;

