import React, { useEffect, useState, location } from "react";
import axios from 'axios';
import { Amplify, Auth } from 'aws-amplify';
import awsconfig from '../bws-exports';

var path = window.location.href;
if (path.includes("?")) {
    path = window.location.href.slice(0, window.location.href.lastIndexOf('?'));
    //console.log(path);
}
if (path.includes("#")) {
    path = window.location.href.slice(0, window.location.href.lastIndexOf('#'));

}


/*
BILL TODO Help:
1) on check-in, select random winner (1 out of 25 odds?). However, only allow a person to win once. If they keep playing after they win, then simply tell them they lost each time they play.
2) if a user wins, return a value in the API letting me know (so I can show the golden ticket), then save a value in a new field called winner, winner = true
3) provide a view of all winners that we can print out at the end of the day
4) if the user loses, randomly show one of the following messages:
"I'm sorry to inform you that you didn't win the prize, but don't worry, you're still a winner in our hearts."
"Unfortunately, you weren't the lucky winner this time. But don't give up, there's always another chance to win!"
"Better luck next time! Keep playing and maybe you'll get lucky."
"Sorry, but you didn't win the prize. However, you did win the satisfaction of knowing you gave it your best shot!"
"Unfortunately, your number didn't come up this time. But who knows, maybe the next time you play, you'll be the big winner!"
"We regret to inform you that you didn't win the prize this time. But hey, at least you tried and that's what counts."
"I'm sorry, but the prize has gone to someone else. But don't be discouraged, keep playing and you might just win the next one."
"Unfortunately, luck wasn't on your side this time. But don't worry, there are plenty of other prizes out there waiting for you to win them!"
"Sorry, but it looks like someone else was luckier than you this time. But keep playing and who knows, maybe next time you'll be the lucky one."
"I'm sorry to say that you didn't win the prize this time. But remember, the thrill is in the playing, not just the winning!"

*/


awsconfig.oauth.redirectSignIn = path;

Amplify.configure(awsconfig);
// >>New - Configuring Auth Module
Auth.configure(awsconfig);
var checkindone = false;

function Checkin() {
    const queryParameters = new URLSearchParams(window.location.search);
    var boothid = queryParameters.get("booth");
    var checkin = queryParameters.get("checkin");
    var boothCSS = "";

    const [loggeduser, setLoggeduser] = useState({});

    const [message, setMessage] = useState('');

    const handleChange = (event) => {
        // 👇 Get input value from "event"
        sessionStorage.setItem('comment', event.target.value);
        //setMessage(event.target.value);

    };
    const [booth, setBooth] = useState({});
    const [winner, setWinner] = useState({});

    useEffect(() => {

        Auth.currentAuthenticatedUser({
            bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
        })
            .then((user) => {
                console.log(user);

                setLoggeduser(user);
                console.log(boothid);
                if (!boothid) {
                    boothid = sessionStorage.getItem('boothid');
                    console.log(boothid);
                }
                if (!checkin) {
                    checkin = sessionStorage.getItem('checkin');
                }

                if (checkin == 1) {
                    if (!checkindone) {
                        checkindone = true;

                        const response = axios.post('https://76hwbexd1g.execute-api.us-east-1.amazonaws.com/prod', {
                            User: user.username,
                            Booth: boothid,
                            Time: new Date().toLocaleString(),
                            MoreInfo: false,
                            Name: user.attributes.name,
                            Comment: ""
                        }).then((response) => {
                            console.log(response.data.body);
                            checkindone = true;
                            if (response.data.body.IsWinner) {

                                console.log("This is a winner");
                                setWinner('<center><img src="/img/GallovationGoldenTicket.png"></img></center>');

                            }
                            else {
                                setWinner("<div style='padding:10px;background-color:gold; font-weight:bold;border-bottom:1px solid black;'>Gallovation Golden Ticket Contest</div><div style='border:1px solid black;padding:20px;font-size:16px;font-weight:bold;'>Unfortunately, your number didn't come up this time. But who knows, maybe the next time you play, you'll be the big winner! Keep scanning QR codes at every booth!</div>");
                                console.log("");
                            }

                        }
                        );
                    }
                }

                    axios.get('https://76hwbexd1g.execute-api.us-east-1.amazonaws.com/prod/booth?BoothID=' + boothid)
                        .then(response => {

                            setBooth(response.data.body[0]);

                        });
                
            })

            .catch((err) => {
                console.log(err);

                sessionStorage.setItem('boothid', boothid);
                sessionStorage.setItem('checkin', checkin);

                console.log(loggeduser.username);
                if (!loggeduser.username) {
                    Auth.federatedSignIn({ customProvider: "Gallo" });
                }
            });
    },
        []);


    if (!loggeduser.username) return null;


    if (!booth) return null;



    return (
        <div>
            <div style={{ top: 0, position: 'sticky', paddingLeft: 40, lineHeight: '60px', color: 'white', width: '100%', backgroundColor: '#440017', height: 60, borderBottomColor: '#CA1E33', borderBottomWidth: 5, borderBottomStyle: 'solid' }}>
                <a style={{ textDecoration: 'none', color: 'black', verticalAlign: 'middle' }} href="/"><img src='/img/left-chevron.png' style={{ height: 24, filter: 'invert(100%)' }} /> <img style={{height:40,marginTop:10}} src="img/Gallovation_New_Logo_Short_White.png" /></a>
            </div>
            <div style={{ margin: '40px' }}>

                <div>

                    <h1><span style={{ color: '#440017' }}>#{booth.ID}</span> <span style={{ color: '#CA1E33' }}> | </span>{booth.Title}</h1>
                    <h3>{booth.Description}</h3>
                    <h4 style={{ color: '#6e6e72', fontStyle: 'italic' }}>Contact(s): {booth.Contact}</h4>

                   
                    <p><div className="boothImage" dangerouslySetInnerHTML={{ __html: booth.Image }} /></p>


                    <h4>Exhibit Links:</h4>
                    <p><div dangerouslySetInnerHTML={{ __html: booth.Links }} /></p>

                    <p style={{ padding: 20, backgroundColor: 'lightgray', lineHeight: '25px' }}>Inspired? See a potential use case for our business?<br /><a style={{ color: 'black', fontWeight: 'bold' }} target="_blank" href="https://www.gallovation.com/subdomain/tech-fair/end/qm_node_wizard_add/sub_sys_2727/6?qmzn=XSJiFX">Submit your idea on Gallovation.com today!</a></p>
                    <br />
                    <label style={{ fontSize: 14, marginBottom: 0 }}>Send a Comment: </label>
                    <textarea name='commentsTxt'
                        style={{ width: '100%', marginTop: 0, height: 50, fontSize: 14 }}
                        defaultValue=""
                        onChange={handleChange}
                    ></textarea>
                    <button style={{ cursor: 'pointer', fontSize: 16, fontWeight: 'bold', backgroundColor: '#440017', color: 'white', padding: 20, border: 0 }} onClick={moreInfo}>Send</button><br />
                </div>


            </div>
        </div>




    );
}


const moreInfo = () => {
    var comment = sessionStorage.getItem('comment');
    CheckInUser(true, comment);
}

async function CheckInUser(moreinfo = false, comments = "") {
    var iswin = false;
    const queryParameters = new URLSearchParams(window.location.search)
    var booth = queryParameters.get("booth")
    if (!booth) {
        booth = sessionStorage.getItem('boothid');

    }
    console.log("Start checkin");


    Auth.currentAuthenticatedUser({
        bypassCache: false // Optional, By default is false. If set to true, this call will send a request to Cognito to get the latest user data
    })
        .then((user) => {


            console.log(user);
            console.log(user.attributes.name);
            const response = axios.post('https://76hwbexd1g.execute-api.us-east-1.amazonaws.com/prod', {
                User: user.username,
                Booth: booth,
                Time: new Date().toLocaleString(),
                MoreInfo: moreinfo,
                Name: user.attributes.name,
                Comment: comments
            }).then((response) => {
                console.log(response.data.body);
                checkindone = true;
                if (response.data.body.IsWinner) {
                    iswin = true;
                    console.log("This is a winner");

                    return true;
                }

            }
            );


            if (moreinfo) {
                alert("Thank you!");
            }

        })
        .catch((err) => { console.log(err); });
    return false;

}

export default Checkin;

